import {
  ACTIVATE_USER,
  SET_USER,
  SET_USER_TOKEN,
  SET_LOADING_STATUS,
  SET_LOADING_MESSAGE,
  SET_ERRORS,
} from "./actionType";
import { BASE_URL } from "../utils/constants";
import * as messages from "../utils/messages";

export const setUserActivationStatus = (payload) => ({
  type: ACTIVATE_USER,
  activate: payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  user: payload,
});

export const setUserToken = (payload) => ({
  type: SET_USER_TOKEN,
  token: payload,
});

export const setLoading = (status) => ({
  type: SET_LOADING_STATUS,
  status: status,
});

export const setLoadingMessage = (message) => ({
  type: SET_LOADING_MESSAGE,
  loading_message: message,
});

export const setErrors = (errors) => ({
  type: SET_ERRORS,
  errors: errors,
});


export function signUpAPI(data) {
  return (dispatch) => {
    dispatch(setLoading(true));
    const url = `${BASE_URL}/users/signup/`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json", //vnd.api+
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          dispatch(setUserActivationStatus(true));
          dispatch(setLoadingMessage(messages.SIGNUP_SUCCESS_MESSAGE));
        } else if (data.failed) {
          console.log(data.errors);
          dispatch(setErrors(data.errors));
          dispatch(setLoading(false));
        }
      })
      .catch((errorMessage) => {
        console.log(errorMessage);
        dispatch(setLoadingMessage(messages.SIGNUP_FAILED_MESSAGE));
      });
  };
}

export const getUserTokenAPI = (payload) => (dispatch) => {
  const url = `${BASE_URL}/users/token/`;

  fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) throw new Error(response.status);
      else return response.json();
    })
    .then((data) => {
      dispatch(setUserToken(data));
    })
    .catch((error) => {
      alert(error.message);
    });
};

export function loginAPI(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));

    const url = `${BASE_URL}/users/login/`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          dispatch(setUser(data.user_data));
          dispatch(
            getUserTokenAPI({
              email: data.user_data.email,
              password: payload.password,
            })
          );
        } else if (data.failed) {
          console.log(data.errors);
          dispatch(setErrors({ login: data.errors }));
          dispatch(setLoading(false));
        }
      })
      .catch((errorMessage) => {
        console.log(errorMessage);
      });
  };
}

export function logOutAPI() {
  return (dispatch) => {
    dispatch(setUser(null));
  };
};


// ----------------------------
// ------ SEND MAIL API -------

export function sendEmailAPI(payload) {
  return (dispatch) => {
    dispatch(setErrors(null));
    const url = "https://kofficobbin.pythonanywhere.com/mail/";

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*", 
        // "Content-Type": "multipart/form-data",
      },
      body: payload,
    })
      .then((response) => response.json())
      .then((data) => {
          alert(`Message sent successfully`);          
        })   
  };
}