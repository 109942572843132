import { ACTIVATE_USER, SET_USER, SET_USER_TOKEN } from "../actions/actionType";


const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem('user')),
    activate_user: false,
    token: JSON.parse(localStorage.getItem('user-token')),
};


const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case SET_USER:
            if (action.user===null){
                localStorage.removeItem('user');
                localStorage.removeItem('user-token');
            }
            else {localStorage.setItem('user', JSON.stringify(action.user));}
            return {
                ...state,
                user: action.user
            };

        case ACTIVATE_USER:
            return {
                ...state,
                activate_user: action.activate
            };

        case SET_USER_TOKEN:
            localStorage.setItem('user-token', JSON.stringify(action.token));
            return {
                ...state,
                token: action.token
            };

        default:
            return state;
    }
}

export default userReducer;
