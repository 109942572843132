import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Chemicals from "./Chemicals";
import Engineering from "./Engineering";
import Technology from "./Technology";


const Services = (props) => {
  let { serviceId } = useParams();

  return (
    <>
      <Container>
        <EventImageWrapper>
          <EventImage imgUrl="/images/1.jpg" />
          <ImageOverlay />
          <PageTitle>
            {serviceId === "chemicals" &&
              <h1>Chemicals & Additives</h1>
            }
            {serviceId === "engineering" &&
              <h1>Engineering Services</h1>
            }
            {serviceId === "technology" &&
              <h1>Innovation Technology</h1>
            }
          </PageTitle>
        </EventImageWrapper>
        
        {serviceId === "chemicals" &&
          <Chemicals />
        }
        {serviceId === "engineering" &&
          <Engineering />
        }
        {serviceId === "technology" &&
          <Technology />
        }
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  background: #fff;
`;

const EventImageWrapper = styled.div`
  width: 100%;
  height: 60vh;
  background: #fff;
  margin-top: -7px;
  position: relative;
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 60vh;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.556;
  border-radius: 0 0 15% 15%;
`;

const EventImage = styled.div`
  width: 100%;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  height: inherit;
  border-radius: 0 0 15% 15%;
  background-image: ${(props) => `url(${props.imgUrl})`};
`;

const PageTitle = styled.div`
  width: fit-content;
  margin-top: -30%;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  @media (min-width: 769px) {
    margin-top: -10%;
  }
`;

export default Services;
